import {
  FC,
  useState,
  useRef,
  useEffect,
} from "react";
import Link from "next/link";

import { Theme, themeRootClassMap } from "apps/website/maps/Theme.map";
import useWindowSize from "apps/website/hooks/useWindowSize";
import Text from "apps/website/components/base/Text/Text";
import Container from "apps/website/components/layout/Container/Container";
import Grid from "apps/website/components/layout/Grid/Grid";
import Column from "apps/website/components/layout/Column/Column";
import Image, { IImageProps } from "apps/website/components/base/Image/Image";
import {
  legacySizeCollectionMap,
} from "apps/website/components/base/Text/Text.map";

export interface IAnnouncement {
  text: string;
  to?: string;
  image?: IImageProps
}

export interface IAnnouncementBanner {
  announcements: IAnnouncement[];
  theme?: Theme;
  className?: string;
}

const AnnouncementBanner: FC<IAnnouncementBanner> = ({ announcements, theme = "brand", className }) => {

  const { windowSize } = useWindowSize();

  const announcementBanner = useRef(null);
  const announcementBannerContent = useRef<HTMLDivElement | null>(null);
  const announcementParts = useRef<HTMLElement[]>([]);
  const [ currentAnnouncement, setCurrentAnnouncement ] = useState<number>(0);
  const [ isOpen ] = useState(true);
  const [ , setAnnouncementBannerHeight ] = useState<string | undefined>(undefined);

  const textComponent = (text: string) => <Text tag="span" display="subtitle" align="center" size={legacySizeCollectionMap.titleXs}>{ text }</Text>;

  useEffect(() => {
    setAnnouncementBannerHeight(`${announcementBannerContent.current?.offsetHeight}px`);
  }, [ windowSize ]);

  useEffect(() => {
    setTimeout(async () => {
      const nextAnnouncement = currentAnnouncement < announcements.length - 1 ? currentAnnouncement + 1 : 0;
      announcementParts.current[currentAnnouncement]?.classList.add("opacity-0");
      announcementParts.current[currentAnnouncement]?.classList.remove("opacity-100");
      setTimeout(() => {
        announcementParts.current[currentAnnouncement]?.classList.add("hidden");
        announcementParts.current[nextAnnouncement]?.classList.remove("hidden");
        announcementParts.current[nextAnnouncement]?.classList.add("opacity-100");
        announcementParts.current[nextAnnouncement]?.classList.remove("opacity-0");
      }, 500);

      setCurrentAnnouncement(nextAnnouncement);
    }, 5000);
  }, [ currentAnnouncement, announcements.length ]);

  return <>
    <div
      data-component="AnnouncementBanner"
      className={`z-[45] w-full fixed h-[44px] flex justify-center items-center transition-all duration-500 box-border overflow-hidden ${themeRootClassMap[theme]} ${className} `}
      data-theme={theme}
      data-state={isOpen ? "open" : "closed"}
      aria-hidden={!isOpen}
      ref={announcementBanner}
    // style={{ "--banner-height": announcementBannerHeight } as React.CSSProperties}
    >
      <div ref={announcementBannerContent}>
        <Container>
          <Grid>
            <Column align="center" justify="center">
              { announcements.map((announcement, index) => (
                <div key={`announcement-${index}`}>
                  { announcement.to ? (
                    (<Link
                      href={announcement.to}
                      ref={(announcementBody) => {
                        if (announcementBody) {
                          announcementParts.current[index] = announcementBody;
                        }
                      }}
                      tabIndex={isOpen && (currentAnnouncement === index) ? 0 : -1 }
                      aria-hidden={isOpen && (currentAnnouncement === index) ? "false" : "true" }
                      className={`flex items-center justify-center transition-opacity duration-500 ${index === 0 ? "opacity-100" : "opacity-0 hidden"}`}>

                      { textComponent(announcement.text) }
                      { announcement.image?.src && (
                        <div className="pl-2 w-[100px] hidden lg:block">
                          <Image image={announcement.image} alt={announcement.text} />
                        </div>
                      ) }

                    </Link>)
                  ) : textComponent(announcement.text) }
                </div>
              )) }
            </Column>
            { /* <Column spans={2} align="end" justify="center">
            <IconButton
              icon="cross"
              label="Close Announcement Banner"
              onClick={closeAnnouncementBanner}
              size="small"
              tabIndex={tabIndex}
            />
          </Column> */ }
          </Grid>
        </Container>
      </div>
    </div>
    <div className={`h-[44px] w-full ${className}`}></div>
  </>;
};

export default AnnouncementBanner;
