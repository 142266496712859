"use client";

import { FC } from "react";

import { Theme } from "apps/website/maps/Theme.map";
import {
  INewsletterSignUp,
} from "apps/website/components//feature/NewsletterSignUp/NewsletterSignUp";

import { IImageProps } from "../../base/Image/Image";

export interface ISectionNewsletterSignUpModal extends INewsletterSignUp {
  theme?: Theme
  image: IImageProps;
}
// ({title, completeTitle, body, completeBody, buttonText, image, theme = "default",} )
const SectionNewsletterSignModal: FC<ISectionNewsletterSignUpModal> = () => (<></>);

// const { modalId, modalTitleId, modalDescriptionId, modalButtonId, modalIsOpen, setModalIsOpen } = useModal(false);
// const { initFocusTrap, destroyFocusTrap } = useFocusTrap();
// const auth = useAuth();
// const todaysDate = dayjs().format("YYYY-MM-DD");
// const suppressUntilDate = dayjs().add(14, "days").format("YYYY-MM-DD");
// const MODAL_LAST_VIEWED_KEY = "newsletterPopUpLastViewed";
// const MODAL_SHOW_DELAY = 10000; // 10 seconds

// const trackEvent = useGA();

// useEffect(() => {
//   if (modalIsOpen) {
//     initFocusTrap(modalId);
//     trackEvent({
//       category: "NEWSLETTER_SIGNUP",
//       action: "MODAL_SHOWN",
//     });
//   } else {
//     destroyFocusTrap(modalButtonId);
//   }
// }, [ modalIsOpen ]);

// useEffect(() => {
//   if (!auth?.loggedIn && (
//     (localStorage[MODAL_LAST_VIEWED_KEY] < todaysDate) || !localStorage[MODAL_LAST_VIEWED_KEY]
//   )
//   ) {
//     setTimeout(() => {
//       setModalIsOpen(true);
//     }, MODAL_SHOW_DELAY);
//   }
// }, [ auth?.loggedIn ]);

// const closeModal = () => {
//   localStorage[MODAL_LAST_VIEWED_KEY] = suppressUntilDate;
//   setModalIsOpen(false);
// };

// (
//   <>
//     { /*
//       <Modal
//         component="SectionNewsletterSignUpModal"
//         onClose={closeModal}
//         id={modalId}
//         isOpen={modalIsOpen}
//         modalTitleId={modalTitleId}
//         modalDescriptionId={modalDescriptionId}
//         size="sm"
//       >
//         <Grid gap="none">
//           <Column className={themeRootClassMap[theme]}>
//             <Image image={image} alt={title} />
//             <div className="py-4 px-3 lg:py-12">
//               <NewsletterSignUp
//                 title={title}
//                 titleDisplay="title"
//                 titleId={modalTitleId}
//                 completeTitle={completeTitle}
//                 body={body}
//                 bodyId={modalDescriptionId}
//                 completeBody={completeBody}
//                 buttonText={buttonText}
//                 layout="tertiary"
//                 size="lg"
//                 source="Modal"
//                 hideLabels
//               />
//             </div>
//           </Column>
//         </Grid>
//       </Modal>
//      */ } { /* Looks like it was part of an unused experiment - Leaving here for now - 2023-01-10 */ }
//   </>
// );

export default SectionNewsletterSignModal;
