"use client";

import {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import dayjs from "dayjs";

import {
  ImageMax,
  getStoryBlokImage,
} from "apps/website/utils/storyblok/image";
import { getStoryBlokLink } from "apps/website/utils/storyblok/links";
import { RAF_STARTED_AT } from "apps/website/constants/storage";
import { useAuth } from "@auth/client-sdk-react";

import AnnouncementBanner, {
  IAnnouncement,
} from "../../page/AnnouncementBanner/AnnouncementBanner";
import {
  IStoryBlokAnnouncement,
  IStoryBlokAnnouncementBanner,
} from "../../page/api.types";

export interface IStoryBlokAnnouncementBannerProps {
  pageAnnouncements?: IStoryBlokAnnouncement[];
  defaultAnnouncements?: IStoryBlokAnnouncementBanner,
  domPosition?: "ABOVE_HEADER" | "BELOW_HEADER"
}

export const getAnnouncements = (announcements: IStoryBlokAnnouncement[]): IAnnouncement[] => announcements.map(
  (announcement) => (
    {
      text: announcement.text,
      to: getStoryBlokLink(announcement.link),
      image: announcement.image ? getStoryBlokImage(announcement.image, true, ImageMax.ICON_SM) : undefined,
    }
  ),
);

export const StoryBlokAnnouncementBanner: FC<IStoryBlokAnnouncementBannerProps> = (
  { pageAnnouncements, defaultAnnouncements, domPosition = "ABOVE_HEADER" },
) => {

  const { loggedIn } = useAuth();

  const [ announcements, setAnnouncements ] = useState<IStoryBlokAnnouncement[]>([]);

  const getDefaultAnnouncementList = useCallback((): IStoryBlokAnnouncement[] => {
    const rafStartedAt = localStorage.getItem(RAF_STARTED_AT);

    // The user has been previously referred by a friend
    if (rafStartedAt && rafStartedAt > dayjs().subtract(28, "days").format("YYYY-MM-DD") && !loggedIn) {
      if (defaultAnnouncements?.announcements_abandoned_cart?.length) {
        return defaultAnnouncements.announcements_abandoned_cart;
      }
    }
    /*
      TODO: Add more rules for things like:
        * authed fresh only user
        * authed scoop only user
        * authed fresh and scoop user
    */
    return defaultAnnouncements?.announcements || [];
  }, [ loggedIn, defaultAnnouncements ]);

  useEffect(() => {
    setAnnouncements(pageAnnouncements?.length ? pageAnnouncements : getDefaultAnnouncementList());
  }, [ loggedIn ]);

  const memoizedAnnouncements: IAnnouncement[] = useMemo(() => getAnnouncements(announcements), [ announcements ]);

  return (
    <>
      <AnnouncementBanner
        announcements={memoizedAnnouncements}
        className={domPosition === "ABOVE_HEADER" ? "hidden xl:flex top-0" : "flex xl:hidden top-[50px]"}
      />
    </>
  );
};
