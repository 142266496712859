export type Display = "default" | "top";

export const displayClassMap: Record<Display, string> = {
  default: "bottom-0 fixed",
  top: "bg-black transform translate-y-[50px] xl:translate-y-[88px]",
};

export const onHideClassMap: Record<Display, string> = {
  default: "translate-y-full",
  top: "-mt-[116px] lg:-mt-[66px] invisible",
};
