"use client";

import ErrorPage from "../components/page/ErrorPage/ErrorPage";

export default function Error() {
  return (
    <ErrorPage
      title={"Looks like something went wrong..."}
      body={"Please try again later"}
      ctas={[
        {
          id: "home",
          to: "/",
          name: "Back to home page",
        },
      ]}
    />
  );
}
