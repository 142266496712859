"use client";

import { FC, useEffect, useState } from "react";

import { themeRootClassMap } from "apps/website/maps/Theme.map";
import Button from "apps/website/components/base/Button/Button";
import { IButton } from "apps/website/components/base/Button/Button.map";
import Text from "apps/website/components/base/Text/Text";
import Column from "apps/website/components/layout/Column/Column";
import Container from "apps/website/components/layout/Container/Container";
import Grid from "apps/website/components/layout/Grid/Grid";
import Section from "apps/website/components/layout/Section/Section";

import { Display, displayClassMap, onHideClassMap } from "./CookieBar.map";

export interface ICookieBar {
  body: string;
  cta: IButton;
  display?: Display;
}

const CookieBar: FC<ICookieBar> = ({ body, cta, display = "default" }) => {

  const [ showCookieBanner, setShowCookieBanner ] = useState(false);

  useEffect(() => {
    if (localStorage.getItem("acceptCookies") !== "true") {
      setShowCookieBanner(true);
    }
  }, [ showCookieBanner, setShowCookieBanner ]);

  const acceptCookies = () => {
    setShowCookieBanner(false);
    localStorage.setItem("acceptCookies", "true");
  };

  return (
    <>
      <Section
        component="CookieBar"
        size="none"
        className={`w-full z-40 transform duration-300 transition-all ${displayClassMap[display]} ${!showCookieBanner ? onHideClassMap[display] : "visible"}`}
      >
        <Container>
          <div className={` py-2 px-4 rounded-t-lg ${themeRootClassMap.dark}`} data-theme="dark" aria-hidden={!showCookieBanner}>
            <Grid>
              <Column direction="row" align="center" justify="center">
                <Text tag="span" display="subtitle" size="sm" className="pr-4">{ body }</Text>
                <Button
                  onClick={acceptCookies}
                  design={cta.design}
                  color={cta.color}
                  disabled={!showCookieBanner}
                >
                  { cta.name }
                </Button>
              </Column>
            </Grid>
          </div>
        </Container>
      </Section>
    </>
  );
};

export default CookieBar;
